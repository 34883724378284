<template>
  <div>
    <master-component
      ref="masterComponent"
      :config="config"
      :showAdd="false"
      :showEdit="false"
      @clicked-search="clickedSearch"
    >
      <template v-slot:table-type>
        ส่วนแรก 60%
      </template>

      <template v-slot:table-custom-event-button="{item}">
        <div
          style="margin-top:4px; margin-bottom:4px"
          class="text-left"
        >

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                small
                v-bind="attrs"
                v-on="on"
                v-if="item.showButtonCheck"
              >
                เทียบงาน
              </v-btn>
            </template>
            <v-card style="width:500px">
              <CustomUploadFile
                @change="handleFile(item,$event)"
                :accept="'excel'"
                :multiple="false"
              ></CustomUploadFile>
            </v-card>
          </v-menu>

          <!-- <v-btn
            color="primary"
            small
          >เทียบงาน</v-btn> -->

          <v-btn
            color="primary"
            class="ml-1"
            small
            @click="downloadReport(item)"
            v-if="item.showButtonDownload"
          >ดาวน์โหลด</v-btn>

          <v-btn
            color="primary"
            class="ml-1"
            small
            @click="cancelReport(item)"
            v-if="item.showButtonCancel"
          >ยกเลิก</v-btn>
        </div>
      </template>

      <template v-slot:master-component-center>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="primary"
              class="ml-1 mt-3"
              @click="openCreateDialog(item)"
            >+ สร้าง</v-btn>
          </v-col>
        </v-row>
      </template>
    </master-component>

    <custom-dialog
      ref="createReportDialog"
      :width="650"
      title=""
      @submit="submitCreateDialog"
    >
      <v-col>
        <master-render
          :rows="dialogRows"
          v-model="modelCreate"
        ></master-render>
      </v-col>
    </custom-dialog>

    <custom-dialog
      ref="checkReponseDialog"
      :width="650"
      title=""
      @submit="submitCreateDialog"
    >
      <v-col>
        <CustomUploadFile @change="handleFile"></CustomUploadFile>

      </v-col>
    </custom-dialog>
  </div>
</template>

<script>
import {
  InputType,
  StatusCase,
  UserRole,
  StatusPayment,
  Customer,
} from "./../js/constants";
import moment from "moment";

import CustomDialog from "./../components/custom/custom-dialog";
import { createDynamicModel } from "./../js/custom";
import { momentToThaiFormat } from "./../js/date-helper";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import { getBranches } from "../js/dropdown-service";
import CustomUploadFile from "./../components/custom/custom-uploadfile.vue";
import UploadFileButton from "./../components/custom/uploadfile-button.vue";

export default {
  components: {
    "custom-dialog": CustomDialog,
    CustomUploadFile,
    UploadFileButton,
  },
  data() {
    return {
      criteria: {},
      modelCreate: {},
      config: {},
      items: [],
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "billing",
        shortName: "billing",
        search: this.searchSection,
        form: {},
        url: this.urlSection,
        table: this.tableSection,
        permission: {},
      };
    },
    clickedSearch(criteria) {
      // this.criteria = criteria;
      // this.$nextTick(() => {
      //   this.items = this.$refs.masterComponent.getItems();
      // });
      // if (criteria.customer.id === Customer.AIRA_ID) {
      //   this.config.table = getAIRA();
      // } else if (criteria.customer.id === Customer.AEON_ID) {
      //   this.config.table = getAEON();
      // }
    },
    downloadFile() {
      let url = "/report/issue/download";
      this.$http.downloadFile(url, this.criteria);
    },
    openCreateDialog() {
      this.$refs.createReportDialog.hideLoading();
      this.$refs.createReportDialog.showDialog();
    },
    submitCreateDialog() {
      let url = `/AEONRequestIssue/Create`;
      let payload = _.cloneDeep(this.modelCreate);

      this.$http.post(url, payload).then((res) => {
        this.$refs.masterComponent.search();

        this.showAlertSuccess();
        this.$refs.createReportDialog.hideDialog();
      });
    },

    downloadReport(item) {
      let url = `/AEONRequestIssue/${item.id}/download`;

      this.$http.downloadFile(url);
    },
    cancelReport(item) {
      let url = `/AEONRequestIssue/ ${item.id}/cancel`;

      this.$http.delete(url).then((res) => {
        this.$refs.masterComponent.search();
        this.showAlertSuccess("ยกเลิกข้อมูลเรียบร้อย");
      });
    },
    handleFile(item, files) {
      if (!(files && files.length > 0)) {
        return;
      }

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("fileName", files[0].name);

      let url = `/AEONRequestIssue/ ${item.id}/check`;
      this.$http.uploadFile(url, formData).then((res) => {
        this.$refs.masterComponent.search();
        this.showAlertSuccess("เทียบงานสำเร็จ");
      });
    },
  },
  computed: {
    urlSection() {
      let searchUrl = "/AEONRequestIssue/search";

      return {
        search: searchUrl,
      };
    },
    dialogRows() {
      return [
        [
          {
            name: "lotDate",
            text: "Lot Date",
            type: InputType.monthPicker,
            rule: {
              required: true,
            },
            columnClass: "col-md-4",
          },
          {
            name: "OA",
            text: "OA",
            type: InputType.dropdown,
            data: {
              promise: getBranches(),
            },
            rule: {
              required: true,
            },
            columnClass: "col-md-8",
          },
        ],
      ];
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/customer/report",
              },
              default: {
                id: Customer.AEON_ID,
              },
              rule: {
                required: true,
              },
            },
            {
              name: "branch",
              text: "OA",
              type: InputType.dropdown,
              data: {
                promise: getBranches(),
              },
              rule: {
                required: true,
              },
              default: {
                id: "a173e11d-b2f0-464f-8bb7-c365fdc344a5",
              },
              columnClass: "col-md-4",
            },
            {
              name: "reportType",
              text: "ประเภท",
              type: InputType.dropdown,
              data: {
                promise: this.$http.get("/dropdown/reportTypes").then((res) => {
                  return { items: res.items.slice(0, 1) };
                }),
              },
              rule: {
                required: true,
              },
              default: {
                id: "9b2c2a05-1dd5-4e61-84c9-46d8a021bc7a",
              },
            },
          ],
          [
            {
              name: "date",
              text: "ข้อมูลตั้งแต่วันที่",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months"),
                }),
                end: momentToThaiFormat({ moment: moment() }),
              },
              columnClass: "col-sm-12 col-md-6",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "oa.text",
            text: "OA",
            style: {
              width: "220px",
            },
          },
          {
            name: "lotDate",
            text: "Lot",
            style: {
              width: "70px",
            },
          },
          {
            name: "caseCount",
            text: "จำนวนรายการ",
            style: {
              width: "80px",
            },
            numeric: 0,
          },
          {
            name: "type",
            text: "ประเภท",
            style: {
              width: "100px",
            },
          },
          {
            name: "status.text",
            text: "Status",
            style: {
              width: "100px",
            },
          },
        ],
      };
    },
  },
};

// function getAEON() {
//   return {
//     showColumnAction: false,
//     columns: [
//       {
//         name: "date1_s",
//         text: "วันรับงาน",
//       },
//       {
//         name: "idCardNo",
//         text: "เลขบัตรประชาชน",
//       },
//       {
//         name: "name",
//         text: "ชื่อ-สกุล",
//       },
//       {
//         name: "productType",
//         text: "โปรดักส์บัตร",
//       },
//       {
//         name: "agreementNo",
//         text: "เลขที่สัญญา",
//       },
//       {
//         name: "principalAmount_s",
//         text: "ทุนทรัพย์",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "courtName",
//         text: "ศาล",
//       },
//       {
//         name: "lawyerName",
//         text: "ทนายฟ้อง",
//       },
//       {
//         name: "dateFong",
//         text: "ยื่นฟ้อง",
//       },

//       {
//         name: "blackNumber",
//         text: "เลขคดีดำ",
//       },
//       {
//         name: "courtFeeAmount_s",
//         text: "ค่าธรรมเนียมศาล",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "songKooKwamAmount_s",
//         text: "ค่าส่งคำคู่ความ",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "splitAmount1_s",
//         text: "แยกยอดตามสัญญา(ใบเสร็จทางราชการ)",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "berk1_s",
//         text: "เบิกตามจริง(ใบเสร็จทางราชการ)",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "splitAmount2_s",
//         text: "แยกยอดตามสัญญาค่าดำเนินคดี",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "berk2_s",
//         text: "ค่าใช้จ่ายในการดำเนินคดี",
//         numeric: 2,
//         class: "text-right",
//       },
//       {
//         name: "grandTotal_s",
//         text: "รวมทั้งสิ้น",
//         numeric: 2,
//         class: "text-right",
//       },
//     ],
//   };
// }

// function getAIRA() {
//   return {
//     showColumnAction: false,
//     columns: [
//       {
//         name: "aira1",
//         text: "aira1",
//       },
//       {
//         name: "aira2",
//         text: "aira2",
//       },
//       {
//         name: "aira3",
//         text: "aira3",
//       },
//     ],
//   };
// }
</script>
